<template>
  <v-card class="mx-auto" max-width="344" flat>
    <v-img height="200" :src="dataSource.guide_images[0]"></v-img>
    <v-card-title>
      {{ dataSource.spot_name }}
    </v-card-title>
    <v-card-subtitle>
      <!-- <div>电话: {{ dataSource.tel }}</div> -->
      <div>{{ dataSource.guide_text }}</div>
      <div>営業時間: {{ dataSource.open_hours }}</div>
      <!-- <div>负责人: {{ dataSource.name }}</div> -->
      <!-- <div>负责人电话: {{ dataSource.phone }}</div> -->
    </v-card-subtitle>

    <!-- TODO -->
    <!-- <v-card-actions>
      <v-btn outlined block color="teal accent-4"> Map </v-btn>
    </v-card-actions> -->

    <v-divider class="mx-1"></v-divider>

    <v-card-subtitle class="px-1 pb-0">PICKUP ORDERS</v-card-subtitle>
    <v-list dense v-for="order in dataSource.orders" v-bind:key="order._id">
      <OrderItem :order="order" />
    </v-list>
  </v-card>
</template>

<script>
  import OrderItem from './OrderItem.vue';

  export default {
    name: 'PickupSpotCard',

    components: {
      OrderItem,
    },

    props: {
      dataSource: Object,
    },
  };
</script>
