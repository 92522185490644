<template>
  <v-skeleton-loader
    v-if="loading"
    class="my-4 mx-8"
    boilerplate
    elevation="2"
    type="list-item-avatar-three-line, divider, card-heading"
  ></v-skeleton-loader>

  <v-card v-else class="my-4 mx-8">
    <v-list>
      <v-list-item>
        <v-list-item-avatar color="teal" style="color: white" tile>
          {{ $dayjs(day).format('M/DD') }}
        </v-list-item-avatar>
        <template v-if="pickup_spots.length > 0">
          <v-list-item-content v-for="spot in pickup_spots" v-bind:key="spot._id">
            <v-list-item-title>{{ spot.spot_name }}</v-list-item-title>
            <v-list-item-subtitle>{{ spot.orders.length }} orders</v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template v-else>
          <v-card-text>No Orders</v-card-text>
        </template>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-card-actions @click="pickup_spots.length > 0 && (show = !show)">
      <v-btn color="orange lighten-2" text :disabled="!pickup_spots.length">詳細</v-btn>
      <v-spacer></v-spacer>
      <v-btn icon :disabled="!pickup_spots.length">
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <!-- 同じ受け取り店舗の注文はまとめられる -->
        <div v-for="spot in pickup_spots" v-bind:key="spot._id" class="my-4 mx-4">
          <PickupSpotCard :dataSource="spot" />
        </div>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
  import Methods from '@/api/method.js';
  import PickupSpotCard from './PickupSpotCard.vue';

  export default {
    name: 'PickupDayCard',

    components: {
      PickupSpotCard,
    },

    props: {
      day: Date,
    },

    data: () => ({
      loading: true,
      show: false,
      pickup_spots: [],
    }),

    mounted() {
      this.fetchPicksByDay();
    },

    methods: {
      async fetchPicksByDay() {
        try {
          this.loading = true;

          let today = await Methods.GetOrdersByDay({
            userId: this.$store.state.user.user_id,
            // userId: '62b7f5c57c00fb513b4e4a68',
            dateStart: this.$dayjs(this.day)
              .startOf('day')
              .toDate(),
            dateEnd: this.$dayjs(this.day)
              .endOf('day')
              .toDate(),
            status_in: '1,2,3,4',
          });
          console.log('dateStart', this.day);
          console.log(
            'dateEnd:',
            this.$dayjs(this.day)
              .endOf('day')
              .toDate(),
          );
          this.pickup_spots = today.data?.map(item => ({
            ...item?.pickupspot,
            orders: item?.orders,
          }));
          this.loading = false;
        } catch (error) {
          console.log(error);
          this.loading = false;
        }
      },
    },
  };
</script>
