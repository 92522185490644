<template>
  <div>
    <v-list-item class="px-0">
      <div class="ma-2">
        <v-chip
          label
          v-if="!is_corporation"
          :color="orderStatus.color"
          :text-color="orderStatus['text-color']"
          class="coupon-status"
        >
          {{ orderStatus.label }}
        </v-chip>
      </div>
      <v-list-item-content v-if="is_corporation">
        <v-list-item-title style="white-space: normal; word-break: break-all">
          配送先拠点名: {{ order.company_name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          商品個数:{{ order && order.products && order.products.length }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-content v-else>
        <v-list-item-title v-if="[0, 10, 11].includes(orderStatus.value)">
          受取番号: なし
        </v-list-item-title>
        <v-list-item-title v-else>
          受取番号:{{ order.pickup_boxes.map(({ pickup_code }) => pickup_code).join(',') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          商品個数:{{ order && order.products && order.products.length }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <OrderDialog
          :dataSource="order"
          @pickup_done="change_state($event)"
          :showCode="![0, 10, 11].includes(orderStatus.value)"
        />
      </v-list-item-action>
    </v-list-item>
    <div class="mx-2 text-caption">
      注文日:{{ order && order.createdAt && $dayjs(order.createdAt).format('YYYY/MM/DD HH:mm') }}
    </div>
    <div v-if="is_corporation" class="mx-2 text-caption">受け取り日:{{ order.date }}</div>
    <div v-else-if="[0, 10, 11].includes(orderStatus.value)" class="mx-2 mb-2 text-caption">
      受け取り日: なし
    </div>
    <div v-else class="mx-2 mb-2 text-caption">
      受け取り日:
      {{ $dayjs(order.pickup_datetime_start).format('YYYY/MM/DD HH:mm') }}
      {{ $dayjs(order.pickup_datetime_end).format(' ~ HH:mm') }}
    </div>
    <div v-if="is_corporation" class="mx-2 mb-2 text-caption">担当者氏名:{{ order.name }}</div>
  </div>
</template>

<script>
  import { ORDER_STATUS } from '@/utils/const.js';
  import OrderDialog from './OrderDialog.vue';

  export default {
    name: 'OrderItem',

    components: {
      OrderDialog,
    },

    props: {
      order: Object,
    },

    data: () => ({
      orderStatus: {},
      ORDER_STATUS: ORDER_STATUS,
    }),

    computed: {
      is_corporation: function () {
        return this.$store.state.user.is_corporation;
      },
    },

    mounted() {
      this.orderStatus = this.ORDER_STATUS.find(({ value }) => value === this.order.status);
    },
    methods: {
      change_state(status) {
        this.orderStatus = this.ORDER_STATUS.find(({ value }) => value === status);
        this.order.status = status;
      },
    },
  };
</script>
<style>
  .coupon-status {
    width: 95px;
    justify-content: center;
  }
</style>
