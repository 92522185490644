<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="grey" text small outlined v-bind="attrs" v-on="on">
          詳細
          <v-icon color="grey" dark small>mdi-information</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <div v-if="is_corporation">配送先拠点名: {{ dataSource.company_name }}</div>
          <div v-else-if="showCode">
            受取番号:
            {{ dataSource.pickup_boxes.map(({ pickup_code }) => pickup_code).join(',') }}
          </div>
          <div v-else>受取番号: なし</div>
        </v-card-title>
        <v-card-text v-if="is_corporation" class="text--primary">
          受け取り日:{{ dataSource.date }}
        </v-card-text>
        <v-card-text v-else-if="showCode" class="text--primary">
          受取時間:
          {{ $dayjs(dataSource.pickup_datetime_start).format('YYYY/MM/DD HH:mm') }}
          {{ $dayjs(dataSource.pickup_datetime_end).format(' ~ HH:mm') }}
        </v-card-text>
        <v-card-text v-else class="text--primary">受取時間: なし</v-card-text>
        <v-card-text class="pb-0 text--primary">ご注文内容:</v-card-text>
        <v-card-text>
          <v-list
            nav
            dense
            class="pt-0"
            v-for="product in dataSource.products"
            v-bind:key="product.product_id"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title v-if="product.product_fields">
                  {{ product.product_fields.title }}
                </v-list-item-title>
                <div>
                  <v-chip
                    v-if="
                      product.sku_objects.length > 0 &&
                      product.sku_objects[0].choice_name.length > 0
                    "
                    class="mr-2"
                    x-small
                    label
                  >
                    {{ product.sku_objects[0].choice_name }}
                  </v-chip>
                  <!-- <span v-for="{ choice_name } in product.sku_objects[0]" v-bind:key="choice_name">
                    <v-chip v-if="!!choice_name" class="mr-2" x-small label>
                      {{ choice_name }}
                    </v-chip>
                  </span> -->
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>数量：{{ product.sku_number }}</v-list-item-action-text>
                <v-list-item-action-text>
                  金額：{{ Math.floor(product.sku_price * (1 + product.product_fields.tax)) }} 円
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <div class="py-2 px-4 d-flex justify-space-between">
            <span class="text-subtitle-2">商品の小計:</span>
            <span>{{ dataSource.original_amount }} 円(税込)</span>
          </div>
          <div v-if="!!dataSource.refunded_amount" class="pb-2 px-4 d-flex justify-space-between">
            <span class="text-subtitle-2">ご返金:</span>
            <span>{{ dataSource.refunded_amount }} 円(税込)</span>
          </div>
          <div
            v-if="has_alcohol"
            style="font-size: 11px"
            class="my-4 text-center text-xs font-weight-black"
          >
            <div>上記のご注文を承りました。</div>
            <br />
            <div>20歳未満の者の飲酒は法律で禁止されています。</div>
            <div>20歳未満の者に対して酒類を販売しません。</div>
            <br />
            <div>株式会社レコセレ</div>
            <div>東京都中央区晴海3-10-1 Daiwa晴海ビル2F</div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" plain text @click="contact()">お問い合わせ</v-btn>
          <!-- <FUTURE TODO> -->
          <!-- <template v-if="dataSource.status === 0">
            <v-btn color="warning" @click="dialog = false"> お支払い </v-btn>
          </template> -->
          <template v-if="dataSource.status === 4">
            <v-btn color="primary" @click="orderDone()">受け取り完了</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import Methods from '@/api/method.js';
  import { contactURL } from '@/utils/const.js';
  export default {
    name: 'OrderDialog',

    props: {
      showCode: Boolean,
      dataSource: Object,
    },

    data() {
      return {
        dialog: false,
      };
    },

    computed: {
      has_alcohol() {
        return this.dataSource.products.some((item) => item.product_fields.is_alcohol);
      },
      is_corporation() {
        return this.$store.state.user.is_corporation;
      },
    },

    mounted() {
      // console.log(this.dataSource);
    },

    methods: {
      async orderDone() {
        try {
          this.loading = true;
          await Methods.UpdateOrder({
            id: this.dataSource._id,
            status: 5,
          });
          this.dialog = false;
          this.loading = false;

          this.$emit('pickup_done', 5);
        } catch (error) {
          console.log(error);
          this.loading = false;
        }
      },
      contact() {
        this.dialog = false;
        window.location.href = contactURL;
      },
    },
  };
</script>
